/* min ready */
div#tarteaucitronMainLineOffset,.tarteaucitronBorder {border:0!important;}

#tarteaucitron [aria-pressed="true"] {
    font-weight:700;
}

.tac_visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}

div#tarteaucitronAlertBig:focus {outline: 0;}

.tarteaucitron-modal-open{
    overflow: hidden;
    height: 100%;
}

#tarteaucitronContentWrapper {display:unset;}

/** 10082023 **/
div#tarteaucitronServices {
    border-radius: 8px;
}

button#tarteaucitronClosePanel {
    border-radius: 5px 5px 0 0;
    right: 15px!important;
}
button.tarteaucitron-toggle-group {
    background: transparent!important;
    padding: 10px 0 0;
    cursor: pointer;
}

#tarteaucitronRoot .tarteaucitronIsDenied .tarteaucitronAllow .tarteaucitronCheck::before {
    content: '\2610'!important
}

#tarteaucitronRoot .tarteaucitronIsAllowed .tarteaucitronAllow .tarteaucitronCheck::before {
    content: '\2611'!important
}

#tarteaucitronRoot .tarteaucitronIsDenied .tarteaucitronDeny .tarteaucitronCross::before {
    content: '\2611'!important
}

#tarteaucitronRoot .tarteaucitronIsAllowed .tarteaucitronDeny .tarteaucitronCross::before {
    content: '\2610'!important
}

#tarteaucitronRoot .tarteaucitronAllow .tarteaucitronCheck::before {
    content: '\2610'!important
}

#tarteaucitronRoot .tarteaucitronDeny .tarteaucitronCross::before {
    content: '\2610'!important
}

#tarteaucitronRoot #tarteaucitronServices_mandatory .tarteaucitronCheck::before {
    content: '\2611'!important
}

#tarteaucitronRoot .tarteaucitronCheck::before,
#tarteaucitronRoot .tarteaucitronCross::before {
    font-size: 20px;
}
/* hide useless mandatory button */
#tarteaucitronRoot #tarteaucitronServices #tarteaucitronServices_mandatory .tarteaucitronAsk {
    display: none!important;
}

/* remove icon from the banner */
#tarteaucitronRoot button.tarteaucitronCTAButton,
#tarteaucitronRoot button#tarteaucitronCloseAlert,
#tarteaucitronRoot button#tarteaucitronPrivacyUrl{
    border: 0;
    border-radius: 4px;
}
#tarteaucitronRoot button.tarteaucitronCTAButton .tarteaucitronCross,
#tarteaucitronRoot button.tarteaucitronCTAButton .tarteaucitronCheck {
    display:none;
}

/* dont use bold to avoid bigger button */
#tarteaucitronRoot #tarteaucitron [aria-pressed="true"] {
    font-weight: initial;
    text-shadow: 0px 0px 1px;
}

/* fix padding if no cookies */
#tarteaucitronRoot #tarteaucitronServices li#tarteaucitronNoServicesTitle {
    padding: 20px;
}

/* hide info about cookies number */
#tarteaucitronRoot .tarteaucitronStatusInfo {
    display: none;
}

#tarteaucitronRoot .tarteaucitronName {
    padding-top: 5px;
}
/***************/


/** 14042021 **/
span.tarteaucitronReadmoreSeparator {
    display: inline!important;
}
/******/

/** 09052021 **/
.tarteaucitronName .tacCurrentStatus, .tarteaucitronName .tarteaucitronReadmoreSeparator {
    color: #333!important;
    font-size: 12px!important;
    text-transform: capitalize;
}
/**************/

/** 27032021 **/
button.tarteaucitron-toggle-group {
    display: block;
}
span.tarteaucitronH3 {
    font-weight: 700!important;
}
#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
    font-weight: 500!important;
    font-size: 14px;
    margin-top: 7px;
}
.tarteaucitronLine {
    border-left: 0px solid transparent!important;
}
/*****/

/** PARTNERS LIST **/
html body #tarteaucitronRoot #tarteaucitronAlertBig div.tarteaucitronPartnersList {
    text-align: left;
    background: #ffffff17;
    margin: 15px 0px 10px;
    padding: 15px;
    display: block;
    border-radius: 4px;
}

html body #tarteaucitronRoot #tarteaucitronAlertBig div.tarteaucitronPartnersList b {
    font-weight: 700;
    padding-bottom: 8px;
    display: block;
    font-size: 16px;
}

html body #tarteaucitronRoot #tarteaucitronAlertBig div.tarteaucitronPartnersList ul {
    margin-left: 22px;
}

html body #tarteaucitronRoot #tarteaucitronAlertBig div.tarteaucitronPartnersList ul li {
    list-style: circle;
    font-size: 14px;
}
/**********************/

/** SAVE BUTTON **/
html body #tarteaucitronRoot button#tarteaucitronSaveButton {
    font-size: 18px!important;
    padding: 7px 20px;
    border-radius: 5px;
    cursor: pointer;
}

html body #tarteaucitronRoot div#tarteaucitronSave {
    text-align: right;
    padding: 20px;
    background: #333;
}
/******************/

/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {

    html body #tarteaucitronRoot #tarteaucitron ul#tarteaucitronServices_mandatory .tarteaucitronDeny {
        display: none!important;
    }

    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button,
    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronAsk,
    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronName {
        width:100%!important;
        display: block!important;
        margin-left: 0!important;
        margin-right: 0!important;
        box-sizing: border-box!important;
        max-width: 100%!important;
        margin-bottom: 8px!important;
    }

    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
        padding: 16px!important;
    }

    html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
        display: none!important;
    }

    #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
        width: 100%!important;
        display: inline-block;
    }
    li.tarteaucitronLine .tarteaucitronName span {
        width: 80%!important;
        display: inline-block;
    }
    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
        width: 10%!important;
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 0px;
        padding: 10px 0;
    }
    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:before {
        content: '\0025BE';
        font-weight:700;
        font-size: 14px;
    }
    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:before {
        content: '\0025B4';
    }
}
@media screen and (min-width: 768px) {

    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:after {
        content: '\0025BE';
        font-weight:700;
        font-size: 14px;
        margin-left: 15px;
    }
    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:after {
        content: '\0025B4';
        margin-left: 15px;
    }
}
/****/



/***
* Reset CSS
*/
#tarteaucitronRoot div, #tarteaucitronRoot span, #tarteaucitronRoot applet, #tarteaucitronRoot object, #tarteaucitronRoot iframe, #tarteaucitronRoot h1, #tarteaucitronRoot h2, #tarteaucitronRoot h3, #tarteaucitronRoot h4, #tarteaucitronRoot h5, #tarteaucitronRoot h6, #tarteaucitronRoot p, #tarteaucitronRoot blockquote, #tarteaucitronRoot pre, #tarteaucitronRoot a, #tarteaucitronRoot abbr, #tarteaucitronRoot acronym, #tarteaucitronRoot address, #tarteaucitronRoot big, #tarteaucitronRoot cite, #tarteaucitronRoot code, #tarteaucitronRoot del, #tarteaucitronRoot dfn, #tarteaucitronRoot em, #tarteaucitronRoot img, #tarteaucitronRoot ins, #tarteaucitronRoot kbd, #tarteaucitronRoot q, #tarteaucitronRoot s, #tarteaucitronRoot samp, #tarteaucitronRoot small, #tarteaucitronRoot strike, #tarteaucitronRoot strong, #tarteaucitronRoot sub, #tarteaucitronRoot sup, #tarteaucitronRoot tt, #tarteaucitronRoot var, #tarteaucitronRoot b, #tarteaucitronRoot u, #tarteaucitronRoot i, #tarteaucitronRoot center, #tarteaucitronRoot dl, #tarteaucitronRoot dt, #tarteaucitronRoot dd, #tarteaucitronRoot ol, #tarteaucitronRoot ul, #tarteaucitronRoot li, #tarteaucitronRoot fieldset, #tarteaucitronRoot form, #tarteaucitronRoot label, #tarteaucitronRoot legend, #tarteaucitronRoot table, #tarteaucitronRoot caption, #tarteaucitronRoot tbody, #tarteaucitronRoot tfoot, #tarteaucitronRoot thead, #tarteaucitronRoot tr, #tarteaucitronRoot th, #tarteaucitronRoot td, #tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot canvas, #tarteaucitronRoot details, #tarteaucitronRoot embed, #tarteaucitronRoot figure, #tarteaucitronRoot figcaption, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot output, #tarteaucitronRoot ruby, #tarteaucitronRoot section, #tarteaucitronRoot summary, #tarteaucitronRoot time, #tarteaucitronRoot mark, #tarteaucitronRoot audio, #tarteaucitronRoot video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    /*background: initial;*/
    text-align: initial;
    text-shadow: initial;
}

/* Animation */
#tarteaucitronRoot * {transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot details, #tarteaucitronRoot figcaption, #tarteaucitronRoot figure, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot section {
    display: block;
}
#tarteaucitronRoot ol, #tarteaucitronRoot ul {
    list-style: none;
}
#tarteaucitronRoot blockquote, #tarteaucitronRoot q {
    quotes: none;
}
#tarteaucitronRoot blockquote:before, #tarteaucitronRoot blockquote:after, #tarteaucitronRoot q:before, #tarteaucitronRoot q:after {
    content: '';
    content: none;
}
#tarteaucitronRoot table {
    border-collapse: collapse;
    border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible, #tarteaucitronRoot button:focus-visible {
    outline: 3px dashed #3d86d8;
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
    margin-top: 0!important;
}

div#tarteaucitronServices {
    margin-top: 21px!important;
}

/*#tarteaucitronServices::-webkit-scrollbar {
    width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
    background-color: #ddd;
    outline: 0px solid slategrey;
}*/

div#tarteaucitronServices {
    box-shadow: 0 40px 60px #545454;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width:479px) {
    #tarteaucitron .tarteaucitronLine .tarteaucitronName {
        width: 90% !important;
    }

    #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
        float: left !important;
        margin: 10px 15px 5px;
    }
}

@media screen and (max-width:767px) {
    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer, #tarteaucitron {
        background: #fff;
        border: 0 !important;
        bottom: 0 !important;
        height: 100% !important;
        left: 0 !important;
        margin: 0 !important;
        max-height: 100% !important;
        max-width: 100% !important;
        top: 0 !important;
        width: 100% !important;
    }

    #tarteaucitron .tarteaucitronBorder {
        border: 0 !important;
    }

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
        border: 0 !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
        text-align: left !important;
    }

    .tarteaucitronName .tarteaucitronH2 {
        max-width: 80%;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
        text-align: center !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
        margin-bottom: 5px;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    #tarteaucitron {
        border: 0 !important;
        left: 0 !important;
        margin: 0 5% !important;
        max-height: 80% !important;
        width: 90% !important;
    }
}

/***
 * Common value
 */
#tarteaucitronRoot div#tarteaucitron {
    left: 0;
    right: 0;
    margin: auto;
}

#tarteaucitronRoot button#tarteaucitronBack {
    background: #eee;
}

#tarteaucitron .clear {
    clear: both;
}

#tarteaucitron a {
    color: rgb(66, 66, 66);
    font-size: 11px;
    font-weight: 700;
    text-decoration: none;
}

#tarteaucitronRoot button {
    background: transparent;
    border: 0;
}

#tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
    color: #fff;
}

#tarteaucitron strong {
    font-size: 22px;
    font-weight: 500;
}

#tarteaucitron ul {
    padding: 0;
}

#tarteaucitron .tarteaucitronH1, #tarteaucitron .tarteaucitronH2, #tarteaucitron .tarteaucitronH3, #tarteaucitron .tarteaucitronH4, #tarteaucitron .tarteaucitronH5, #tarteaucitron .tarteaucitronH6 {
    display: block;
}

.cookie-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
/***
 * Root div added just before </body>
 */
#tarteaucitronRoot {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

#tarteaucitronRoot * {
    box-sizing: initial;
    color: #333;
    /*font-family: sans-serif !important;*/
    font-size: 14px;
    line-height: normal;
    vertical-align: initial;
}

#tarteaucitronRoot .tarteaucitronH1 {
    font-size: 1.5em;
    text-align: center;
    color: #fff;
    margin: 15px 0 28px;
}

#tarteaucitronRoot .tarteaucitronH2 {
    display: inline-block;
    margin: 12px 0 0 15px;
    color: #fff;
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
    margin-left: 0;
}

/***
 * Control panel
 */
#tarteaucitronBack {
    background: #fff;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2147483646;
}

#tarteaucitron {
    display: none;
    max-height: 80%;
    left: 50%;
    margin: 0 auto 0 -430px;
    padding: 0;
    position: fixed;
    top: 6%;
    width: 860px;
    z-index: 2147483647;
}

#tarteaucitron .tarteaucitronBorder {
    background: #fff;
    border: 2px solid #333;
    border-top: 0;
    height: auto;
    overflow: auto;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
    background: #333333;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    padding: 4px 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 70px;
}

#tarteaucitron #tarteaucitronDisclaimer {
    color: #555;
    font-size: 12px;
    margin: 15px auto 0;
    width: 80%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
    background: rgba(51, 51, 51, 0.07);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
    display: none;
    position: relative;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
    width: 100%;
    box-sizing: border-box;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails,
#tarteaucitronRoot .asCatToggleBtn {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    margin: 20px 0px 0px;
    padding: 5px 20px;
    text-align: left;
    width: auto;
    background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
    color: #fff;
    font-weight: 500;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
    text-decoration: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
    font-size: 22px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
    font-size: 14px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
    padding: 5px 10px;
    margin: 0;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails:not(.tarteaucitronDetailsInline) {
    color: #fff;
    display: none;
    font-size: 12px;
    font-weight: 500;
    margin-top: 0;
    max-width: 270px;
    padding: 20px;
    position: absolute;
    z-index: 2147483647;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle + [id^="tarteaucitronDetails"] {
    width: calc(100% - 40px);
    font-weight:500;
    margin:0;
    padding:5px 20px 20px;
    background:rgba(51, 51, 51, 0.2);
    color:#333;
}

#tarteaucitron #tarteaucitronInfo a {
    color: #fff;
    text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
    background: rgba(51, 51, 51, 0.2);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
    background: rgba(51, 51, 51, 0.1);
    border-left: 5px solid transparent;
    margin: 0;
    overflow: hidden;
    padding: 15px 5px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
    border-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
    border-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
    background: #333;
    border: 3px solid #333;
    border-left: 9px solid #333;
    border-top: 5px solid #333;
    margin-bottom: 0;
    margin-top: 21px;
    position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
    background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
    margin-left: 15px;
    margin-top: 2px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
    color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
    margin-top: 0px !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
    display: inline-block;
    float: left;
    margin-left: 15px;
    text-align: left;
    width: 50%;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
    text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    display: inline-block;
    float: right;
    margin: 7px 15px 0;
    text-align: right;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow {
    background: #555;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 6px 10px;
    text-align: center;
    text-decoration: none;
    width: auto;
    border: 0;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
    background-color: #1B870B;
    opacity: 1;
}
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
    background-color: #9C1A1A;
    opacity: 1;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices #tarteaucitronServices_mandatory .tarteaucitronLine button.tarteaucitronAllow {
    background-color: #1B870B;
}
#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
    background-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronServices_mandatory .tarteaucitronLine button.tarteaucitronAllow{
    opacity: 0.4;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
    color: #333;
    font-size: 12px;
}

#tarteaucitron .tarteaucitronH3 {
    font-size: 18px;
}

#tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
    width: auto!important;
    margin-left: 0!important;
    font-size: 14px;
}

.tarteaucitronAlertBigBottom span#tarteaucitronDisclaimerAlert,
.tarteaucitronAlertBigTop span#tarteaucitronDisclaimerAlert {
    display: inline-flex;
}
span#tarteaucitronDisclaimerAlert {
    padding: 0 10px;
    display: inline-block;
    overflow-y: auto;
    max-height: 50vh;
    line-height: normal;
}
@media only screen and (max-width: 768px) {
    #tarteaucitronRoot span#tarteaucitronDisclaimerAlert {
        font-size: 16px;
    }
}
#tarteaucitron .tarteaucitronBorder, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
    border-color: #333!important;
}

/***
 * Big alert
 */
.tarteaucitronAlertBigTop {
    top: 0;
}

.tarteaucitronAlertBigBottom {
    bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
    background: #333;
    color: #fff;
    display: none;
    font-size: 15px !important;
    left: 0;
    position: fixed;
    box-sizing: content-box;
    z-index: 2147483645;
    text-align: center;
    padding: 10px 0 10px 0;
    margin: auto;
    width: 100%;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
    /*font: 15px verdana;*/
    color: #fff;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
    font-weight: 700;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog {
    cursor: pointer;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
    background: #008300;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px!important;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
}

#tarteaucitronRoot .tarteaucitronDeny {
    background: #9C1A1A;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
    background: #fff;
    color: #333;
    font-size: 13px;
    margin-bottom: 3px;
    margin-left: 7px;
    padding: 5px 10px;
}

#tarteaucitronPercentage {
    background: #0A0!important;
    box-shadow: 0 0 2px #fff, 0 1px 2px #555;
    height: 5px;
    left: 0;
    position: fixed;
    width: 0;
    z-index: 2147483644;
}

/***
 * Icon
 */
.tarteaucitronIconBottomRight {
    bottom: 0;
    right: 0;
}
.tarteaucitronIconBottomLeft {
    bottom: 0;
    left: 0;
}
.tarteaucitronIconTopRight {
    top: 0;
    right: 0;
}
.tarteaucitronIconTopLeft {
    top: 0;
    left: 0;
}

.tarteaucitronIconTopLeft #tarteaucitronManager {
    border-radius: 2px 7px 7px 2px;
}

.tarteaucitronIconTopRight #tarteaucitronManager {
    border-radius: 7px 2px 2px 7px;
}

.tarteaucitronIconBottomLeft #tarteaucitronManager {
    border-radius: 7px 7px 2px 2px;
}

.tarteaucitronIconBottomRight #tarteaucitronManager {
    border-radius: 7px 7px 2px 2px;
}

#tarteaucitronIcon {
    background: transparent;
    position: fixed;
    /*display: none;*/
    width: auto;
    z-index: 2147483646;
}
#tarteaucitronIcon #tarteaucitronManager {
    color: transparent;
    cursor: pointer;
    display: inline-block;
    font-size: 11px !important;
    padding: 8px 10px 8px;
    border: none;
}
#tarteaucitronIcon #tarteaucitronManager img {
    width: 50px;
    height: 50px;
}

#tarteaucitronRoot .tarteaucitronCross::before {
    content: '\2717';
    display: inline-block;
    color: white;
}

#tarteaucitronRoot .tarteaucitronCheck::before {
    content: '\2713';
    display: inline-block;
    color: white;
}

#tarteaucitronRoot .tarteaucitronPlus::before {
    content: '\271b';
    display: inline-block;
    color: white;
}


/***
 * Small alert
 */

.tarteaucitronAlertSmallTop,.tarteaucitronAlertSmallBottom {
    bottom: 0;
}

#tarteaucitronAlertSmall {
    background: #333;
    display: none;
    padding: 0;
    position: fixed;
    right: 0;
    text-align: center;
    width: auto;
    z-index: 2147483646;
}

#tarteaucitronAlertSmall #tarteaucitronManager {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 11px !important;
    padding: 8px 10px 8px;
}

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
    background: rgba(255, 255, 255, 0.05);
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
    background-color: gray;
    border-radius: 5px;
    display: block;
    height: 8px;
    margin-bottom: 1px;
    margin-top: 5px;
    overflow: hidden;
    width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
    display: block;
    float: left;
    height: 100%;
    width: 0%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
    background-color: #1B870B;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
    background-color: #FBDA26;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
    background-color: #9C1A1A;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 30px;
    padding: 0px 10px;
    vertical-align: top;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
    background: rgba(255, 255, 255, 0.3);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
    display: none;
    max-height: 70%;
    max-width: 500px;
    position: fixed;
    right: 0;
    width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    background: #fff;
    border: 2px solid #333;
    color: #333;
    font-size: 11px;
    height: auto;
    overflow: auto;
    text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList strong {
    color: #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
    background: #333;
    margin-top: 21px;
    padding: 13px 0 9px 13px;
    text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle strong {
    color: #fff;
    font-size: 16px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
    background: rgba(51, 51, 51, 0.1);
    padding: 7px 5px 10px;
    word-wrap: break-word;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain:hover {
    background: rgba(51, 51, 51, 0.2);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain a {
    color: #333;
    text-decoration: none;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft {
    display: inline-block;
    width: 50%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft a strong {
    color: darkred;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListRight {
    color: #333;
    display: inline-block;
    font-size: 11px;
    margin-left: 10%;
    vertical-align: top;
    width: 30%;
}

/***
 * Fallback activate link
 */
.tac_activate {
    background: #333;
    color: #fff;
    display: table;
    font-size: 12px;
    height: 100%;
    line-height: initial;
    margin: auto;
    text-align: center;
    width: 100%;
}

.tac_float {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.tac_activate .tac_float strong {
    color: #fff;
}

.tac_activate .tac_float .tarteaucitronAllow {
    background-color: #1B870B;
    display: inline-block;
}

/***
 * CSS for services
 */
ins.ferank-publicite, ins.adsbygoogle {
    text-decoration: none;
}

div.amazon_product {
    height:240px;
    width:120px;
}

.tarteaucitronIsAllowed .tarteaucitronDeny {
    opacity: 0.4!important;
}.tarteaucitronIsDenied .tarteaucitronAllow {
     opacity: 0.4!important;
 }.tarteaucitronIsAllowed .tarteaucitronAllow {
      opacity: 1!important;
  }.tarteaucitronIsDenied .tarteaucitronDeny {
       opacity: 1!important;
   }
.tarteaucitronLine .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronDeny {
    opacity: 0.4;
}
#tarteaucitronServices_mandatory button.tarteaucitronAllow {
    opacity: 1;
}

div#tarteaucitronInfo {
    display: block!important;
    position: relative !important;
    text-align: center!important;
    max-width: 80%!important;
    padding: 15px 0!important;
    margin: -10px auto 40px!important;
    font-size: 1em!important;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: #555;
}

a.tarteaucitronSelfLink {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    text-align: center!important;
    display: block;
    height:30px;
}

.tarteaucitronMainLine .tarteaucitronH2 {
    font-size: 1.2em!important;
    margin-top: 4px!important;
}

span.tarteaucitronTitle.tarteaucitronH3 {
    margin-top: 12px!important;
}

#tarteaucitronCloseCross {
    position:absolute;
    color: #FFF;
    font-size:1.8rem;
    cursor: pointer;
    top: 10px;
    right: 26px
}

#tarteaucitronCloseCross span {
    color: inherit;
}

.tarteaucitron-spacer-20 {
    height: 20px;
    display: block;
}

.tarteaucitron-display-block {
    display: block;
}

.tarteaucitron-display-none {
    display: none;
}
